import React from 'react';
import { Data } from './data';


const getLang = () => {

    let browserLang
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-underscore-dangle
        browserLang = navigator.language || navigator.userLanguage;
    }
    if (browserLang) {
        const langCode = browserLang.split('-')[0];
        return langCode;
    }
    return 'en';
};
const lng = getLang();
// eslint-disable-next-line no-underscore-dangle
console.log('lng', lng)
const _t = Data.en; //Data[lng] || 



export let NavbarData={}
/*
export let NavbarData = {
    logo: "/logo.png",
    startTitle: 'Launch App',
    startLink: "",
    navigation: [
        { title: "Home", path: "/" },
        { title: "Products", items: [{ title: " ModularBlockchain", target: "ModularLayer", path: "https://memolabs.gitbook.io/memo-docs" }, { title: "MemoMegrez", path: "https://memolabs.gitbook.io/memo-megrez/", target: "MemoMegrez" }, { title: "MeeDA", path: "https://memolabs.gitbook.io/meeda", target: "MeeDA" }], path: "/" },
        { title: "Ecosystem", items: [{ title: "Partner", path: "/partner" },{ title: "Drive3", path: "https://drive3.net/", target: "Drive3" }], path: "/status" }, //, { title: "MiddleWare", path: "https://middleware.metamemo.one/", target: "MiddleWare" }
        { title: "Build", items: [{ title: "Technical", path: "/tec" }, { title: "Wiki", path: "https://memolabs.gitbook.io/", target: "Wiki" }, { title: "Whitepaper", path: "https://docsend.com/view/jnqpex7", target: "Whitepaper" }], path: "/" },
        { title: "Docs", path: " https://memolabs.gitbook.io/memo-docs", target: "Docs" },
        { title: "Blog", path: "https://blog.memolabs.org/", target: "Blog" },
        { title: "About", path: "/about" },
        { title: "Airdrop",focus:true, path: "https://airdrop.memolabs.org/", target: "Airdrop" },
    ]
}
*/

export let About1Data = {

}
export let About2Data = {

}

export let FooterData1 = {
    emailTitle: 'Stay In Touch, Don\'t Miss Out!',
    buttonTitle: "Subscribe",
    buttonLink: ""
}

export let StatusData3 = {

}

export let Home1Data = {


}

export let Home11Data = {



}


export let Home12Data = {

    items: [
        {
            name: "ZK-based data security",
            desc: "ZK technology allows users to perform data verification or transactions (e.g., DID verification, RWA) without the original data being exposed to third parties, greatly enhancing data security and user privacy protection.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
        },
        {
            name: "AI-enabled data resource services",
            desc: "The publicly available data sources as well as resource nodes on MEMO provide rich support for the development of artificial intelligence (AI). These data sources and arithmetic resources are crucial for LLM training and computation of AI.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>
        },
        {
            name: "Rollup and DA schemes enhance Ethereum scalability",
            desc: "MEMO Ecology's OP-Rollup extension solution, Memolayer, and DA solution, MeeDA, have helped increase the transaction throughput of the Ethereum network, reduce transaction costs, and enhance its scalability.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
        },
    ],
    img: './advantage.png',
    bg: './advantage-bg.png',

}


export let Home2Data = {


}

export let Home3Data = {


}

export let Home4Data = {
    title: 'DApp',
    subTitle: "As a decentralized data storage network, MEMO is compatible with mainstream networks such as EVM. It provides solid infrastructure support for the development and deployment of decentralized applications through storage nodes, cross-chain bridges, DA and other solutions.",
    button: '',
    button2: '',

    img: "./dapp.png"

}
export let HomeTimeLineData =
{


}


export let Home5Data = {

}

export let Home6Data = {

}



export let Technical0Data = {

};

export let Technical1Data = {

    items: [
        {
            name: "User",
            desc: "Users of the storage service. Data can be uploaded or downloaded and is subject to a service fee.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
        },
        {
            name: "Provider",
            desc: "Providers of edge storage devices that respond to user requests for data access and management and, at the same time, work with maintainers to repair data.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>
        },
        {
            name: "Keeper",
            desc: "Responsible for ensuring security, reliability and availability. Maintains data mapping information and uses this information to challenge the repository and reach management consensus.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
        },
    ],
    img: "/ptec/t-1.png",
    bg: '/advantage-bg.png',

}


export let Technical2Data = {
    title: 'Storage Proof Process',
    title2: 'Verification Principle',
    subTitle: "For each verification, the Keeper generates a VRF key pair and while saving the VRF private key to the local, and sends the VRF public key to the corresponding Provider, which means that the Provider uses the VRF public key to verify the reliability of the function.  Similarly, the random number generated by the VRF calculation and the response evidence will be sent to the Provider together for the subsequent verification",
    button: '',
    button2: '',
    img: "/ptec/t-2.png",
    bg: '/advantage-bg.png',
}


export let Technical3Data = {

}


export let Technical4Data = {

}

export let PartnersbanerData = {
 }
export let PartnersData = {
};
  
export let FooterData = {

    emailTitle: '',
    button: 'Subscribe',

}

function fetchDataSync(url) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false); // false makes the request synchronous
    xhr.send(null);
  
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText);
    } else {
      throw new Error('Request failed with status ' + xhr.status);
    }
  }



const data = fetchDataSync('./web.json');

NavbarData=data.NavbarData;
    
About1Data=data.About1Data;
About2Data=data.About2Data;

FooterData1=data.FooterData1;
StatusData3=data.StatusData3;
Home1Data=data.Home1Data;
Home11Data=data.Home11Data;
Home12Data={...Home12Data,...data.Home12Data};
Home2Data=data.Home2Data;
Home3Data=data.Home3Data;
Home4Data=data.Home4Data;

HomeTimeLineData=data.HomeTimeLineData;

Home5Data=data.Home5Data;
Home6Data=data.Home6Data;
Technical1Data={...Technical1Data,...data.Technical1Data};
Technical2Data=data.Technical2Data;
Technical3Data=data.Technical3Data;
Technical4Data=data.Technical4Data;
PartnersbanerData=data.PartnersbanerData;
PartnersData=data.PartnersData;
FooterData=data.FooterData;

export default ({ data,id }) => {
    return (
        <section id={id} className="py-4 mt-10" >
            <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
                <div className="max-w-xl space-y-3 md:mx-auto">
                    <h3 className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                        {data.title}
                    </h3>
                    <p className="text-gray-800 text-1xl font-semibold sm:text-2xl">
                        {data.subtitle}
                    </p>

                </div>
                <div className="mt-10 space-y-0 justify-center gap-4 grid  grid-cols-2 lg:grid-cols-5 md:grid-cols-5 sm:space-y-0 ">
                    {
                        data.items.map((item, idx) => (
                            <img src={item} alt="" style={{ width: 120 }} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}